.cities-component {
    overflow: hidden;
}

.cities__content {
    max-width: 1392px;
    padding: 40px 16px;
    margin: 0 auto;

    @include mq($not-mobile-tablet-pt) {
        padding: 44px 40px;
    }
}

.cities__heading {
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 8px;

    @include mq($not-mobile-tablet-pt) {
        font-size: 32px;
    }
}

.cities__description {
    &,
    * {
        font-size: 14px;
        text-transform: none;
        line-height: 1.2;
        max-width: 532px;
    }

    a {
        color: $safety-orange;
    }
}

.cities__slider {
    margin-top: 31px;

    @include mq($not-mobile-tablet-pt) {
        margin-top: 16px;
        overflow: hidden;
    }

    .city {
        width: 205px;
    }
}

.city__content {
    align-items: flex-end;
    display: flex;
    height: 136px;
    max-width: 205px;
    overflow: hidden;
    position: relative;

    a & {
        @include image-hover(".city__image");
    }
}

.city__picture {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    @include image-overlay;
}

.city__name {
    color: $white;
    font-size: 14px;
    font-family: $heading-pro-treble-extra-bold;
    margin: 10px 16px;
    position: relative;

    @include text-truncate;
}

.cities__slider-navigation {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 32px auto 0;
    max-width: 300px;
    position: relative;
    z-index: 1;

    @include mq($not-mobile-tablet-pt) {
        justify-content: center;
        position: static;
        max-width: 100%;
    }

    .swiper-button-disabled {
        opacity: 0;
    }
}

.cities__slider-pagination {
    position: static;

    .swiper-pagination-bullet {
        width: 20px;
        height: 4px;
        display: inline-block;
        border-radius: 3px;
        background: $white-smoke;
        opacity: 1;
        margin: auto 11px;
        transition: all 0.2s ease-in-out;

        :has(.cities__slider-arrow--prev.swiper-button-disabled) & {
            display: none;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
        background: $safety-orange;
        width: 38px;
        height: 6px;
    }

    .mobile-scrollbar & {
        display: none;

        @include mq($not-mobile-tablet-pt) {
            display: block;
        }
    }
}

.cities__slider-arrow--prev {
    left: 30px;
    margin-right: 9px;

    &::before {
        @include iconfont-styles;

        content: svg(swiper-arrow);
        color: $nero;
        display: inline-block;
        font-size: 16px;
        height: 16px;
        line-height: initial;
        position: relative;
        transform: scale(-1, 1);
        vertical-align: middle;
        width: 16px;
    }

    @include mq($not-mobile-tablet-pt) {
        margin-right: 60px;
    }
}

.cities__slider-arrow--next {
    margin-left: 9px;
    right: 30px;
    text-align: right;

    &::after {
        @include iconfont-styles;

        content: svg(swiper-arrow);
        display: inline-block;
        position: relative;
        font-size: 16px;
        vertical-align: middle;
        line-height: initial;
        color: $nero;
        width: 16px;
        height: 16px;
    }

    @include mq($not-mobile-tablet-pt) {
        margin-left: 60px;
    }
}

.cities__slider-scrollbar {
    background-color: $white-smoke;
    border-radius: 3px;
    display: none;
    height: 6px;
    margin: 0 auto;
    width: 120px;

    .mobile-scrollbar & {
        display: block;
    }

    .swiper-scrollbar-drag {
        background-color: $safety-orange;
        border-radius: 3px;
        height: 6px;
    }
}
