/** font family **/
@media only screen and (min-width: 768px) {
  /*! "$not-mobile" */
}
@media only screen and (min-width: 901px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (min-width: 1025px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (max-width: 1599px) {
  /*! "until-dt-max" */
}
@media only screen and (max-width: 1366px) {
  /*! "until-dt-med" */
}
@media only screen and (max-width: 1279px) {
  /*! "until-dt-min" */
}
@media only screen and (min-width: 1280px) {
  /*! "dt-min" */
}
@media only screen and (max-width: 1024px) {
  /*! "tablet-max" */
}
@media only screen and (min-width: 901px) and (max-width: 1024px) {
  /*! "tablet-ls" */
}
@media only screen and (max-width: 900px) {
  /*! "mobile-tablet-pt" */
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  /*! "tablet-pt" */
}
@media only screen and (max-width: 767px) {
  /*! "$mobile-max" */
}
@media only screen and (max-width: 415px) {
  /*! "$mobile-pt" */
}
.cities-component {
  overflow: hidden;
}

.cities__content {
  max-width: 1392px;
  padding: 40px 16px;
  margin: 0 auto;
}
@media only screen and (min-width: 901px) {
  .cities__content {
    padding: 44px 40px;
  }
}

.cities__heading {
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 8px;
}
@media only screen and (min-width: 901px) {
  .cities__heading {
    font-size: 32px;
  }
}

.cities__description,
.cities__description * {
  font-size: 14px;
  text-transform: none;
  line-height: 1.2;
  max-width: 532px;
}
.cities__description a {
  color: #fe7000;
}

.cities__slider {
  margin-top: 31px;
}
@media only screen and (min-width: 901px) {
  .cities__slider {
    margin-top: 16px;
    overflow: hidden;
  }
}
.cities__slider .city {
  width: 205px;
}

.city__content {
  align-items: flex-end;
  display: flex;
  height: 136px;
  max-width: 205px;
  overflow: hidden;
  position: relative;
}
@media (hover: hover) and (pointer: fine) {
  a .city__content .city__image {
    transition: transform 0.2s ease-out;
  }
  a .city__content:hover .city__image {
    transform: scale(1.02);
  }
}

.city__picture {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.city__picture::after {
  background-image: linear-gradient(1deg, rgba(0, 0, 0, 0.5) 46.54%, rgba(0, 0, 0, 0) 76.23%);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.city__name {
  color: #fff;
  font-size: 14px;
  font-family: "HeadingProTreble-ExtraBold", sans-serif;
  margin: 10px 16px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cities__slider-navigation {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 32px auto 0;
  max-width: 300px;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 901px) {
  .cities__slider-navigation {
    justify-content: center;
    position: static;
    max-width: 100%;
  }
}
.cities__slider-navigation .swiper-button-disabled {
  opacity: 0;
}

.cities__slider-pagination {
  position: static;
}
.cities__slider-pagination .swiper-pagination-bullet {
  width: 20px;
  height: 4px;
  display: inline-block;
  border-radius: 3px;
  background: #efefef;
  opacity: 1;
  margin: auto 11px;
  transition: all 0.2s ease-in-out;
}
:has(.cities__slider-arrow--prev.swiper-button-disabled) .cities__slider-pagination .swiper-pagination-bullet {
  display: none;
}
.cities__slider-pagination .swiper-pagination-bullet:first-child {
  margin-left: 0;
}
.cities__slider-pagination .swiper-pagination-bullet:last-child {
  margin-right: 0;
}
.cities__slider-pagination .swiper-pagination-bullet-active {
  opacity: 1;
  background: #fe7000;
  width: 38px;
  height: 6px;
}
.mobile-scrollbar .cities__slider-pagination {
  display: none;
}
@media only screen and (min-width: 901px) {
  .mobile-scrollbar .cities__slider-pagination {
    display: block;
  }
}

.cities__slider-arrow--prev {
  left: 30px;
  margin-right: 9px;
}
.cities__slider-arrow--prev::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  color: inherit;
  content: "\f136";
  color: #2d2d2d;
  display: inline-block;
  font-size: 16px;
  height: 16px;
  line-height: initial;
  position: relative;
  transform: scale(-1, 1);
  vertical-align: middle;
  width: 16px;
}
@media only screen and (min-width: 901px) {
  .cities__slider-arrow--prev {
    margin-right: 60px;
  }
}

.cities__slider-arrow--next {
  margin-left: 9px;
  right: 30px;
  text-align: right;
}
.cities__slider-arrow--next::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  color: inherit;
  content: "\f136";
  display: inline-block;
  position: relative;
  font-size: 16px;
  vertical-align: middle;
  line-height: initial;
  color: #2d2d2d;
  width: 16px;
  height: 16px;
}
@media only screen and (min-width: 901px) {
  .cities__slider-arrow--next {
    margin-left: 60px;
  }
}

.cities__slider-scrollbar {
  background-color: #efefef;
  border-radius: 3px;
  display: none;
  height: 6px;
  margin: 0 auto;
  width: 120px;
}
.mobile-scrollbar .cities__slider-scrollbar {
  display: block;
}
.cities__slider-scrollbar .swiper-scrollbar-drag {
  background-color: #fe7000;
  border-radius: 3px;
  height: 6px;
}